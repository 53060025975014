<template>
  <div>
    <br/>
    <h1 class="_pl-15">Кол-во сегментов попроектно</h1>
    <p class="_pl-15">Обновление данных происходит в 9:00 и 18:00 по Москве</p>
    <table-container
      :id="2"
      :showProblematicFilter="true"
      :showSegmentFilter="true"
      @updTable="updTable"
			@updSegmentType="updSegmentType"
    >
      <template v-slot:total>
        <p v-if="projectTotal">
          <strong>Всего активных проектов: </strong> {{projectTotal}}
        </p>
        <p v-if="segments.length">
          <strong>Найдено проектов: </strong> {{segments.length}}
        </p>
      </template>
      <v-data-table
        :headers="headers"
        :items="segments"
        :loading="problematicSegmentsLoading"
        hide-default-footer
        disable-pagination
        dense
      >
        <template v-slot:header.segment_count_diff="{}">
          <div class="header-btn" @click="sortBy('segment_count_prc')">
            Сегментов Diff
          <v-icon v-if="entity.type === 'segment_count_prc' && entity.isReverse" small>mdi-arrow-up</v-icon>
          <v-icon v-else small>mdi-arrow-down</v-icon>
          </div>
        </template>
        <template v-slot:header.query_count_diff="{}">
          <div class="header-btn" @click="sortBy('query_count_prc')">
            Запросов Diff
            <v-icon v-if="entity.type === 'query_count_prc' && entity.isReverse" small>mdi-arrow-up</v-icon>
            <v-icon v-else small>mdi-arrow-down</v-icon>
          </div>
        </template>
        <template v-slot:header.url_count_diff="{}">
          <div class="header-btn" @click="sortBy('url_count_prc')">
            Страниц Diff
            <v-icon v-if="entity.type === 'url_count_prc' && entity.isReverse" small>mdi-arrow-up</v-icon>
            <v-icon v-else small>mdi-arrow-down</v-icon>
          </div>
        </template>

        <template v-slot:item.name="{ item }">
          <div>{{item.name ? item.name : '-'}}</div>
        </template>
        <template v-slot:item.project_id="{ item }">
					<div class="link-wrapper">
						<span>{{item.project_id}}</span>
						<v-tooltip top>
							<template  v-slot:activator="{ on, attrs }">
								<router-link
									class="link-icon"
									:to="`/data/segments/all?active=1&project_id=${item.project_id}&only_diff=0`"
								>
									<v-icon small color="blue" v-bind="attrs" v-on="on">mdi-shape</v-icon>
								</router-link>
							</template>
							<span>Сравнить сегменты проекта CH vs Main</span>
						</v-tooltip>
					</div>
        </template>
        <template v-slot:item.segment_type="{ item }">
          {{item.segment_type === 'query_group' ? 'Группы запросов' : item.segment_type === 'group' ? 'Группы страниц' : item.segment_type === 'category' ? 'Категории' : item.segment_type}}
        </template>
        <template v-slot:item.segment_count_main="{ item }">
          <span>{{item.main.segment_count}}</span>
          <v-tooltip top>
            <template  v-slot:activator="{ on, attrs }">
              <a
                class="control-btn"
                :href="generateLink(item.segment_type, item.project_id, false)"
                target="_blank"
              >
                <v-icon small color="gray" v-bind="attrs" v-on="on">mdi-link</v-icon>
              </a>
            </template>
            <span>Main</span>
          </v-tooltip>
        </template>
        <template v-slot:item.segment_count_ch="{ item }">
          <span>{{item.chstat.segment_count}}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                class="control-btn"
                :href="generateLink(item.segment_type, item.project_id, true)"
                target="_blank"
              >
                <v-icon small color="blue" v-bind="attrs" v-on="on">mdi-link</v-icon>
              </a>
            </template>
            <span>CH</span>
          </v-tooltip>
        </template>
        <template v-slot:item.segment_count_diff="{ item }">
          <span :class="[item.diff.segment_count_prc !== 0 && 'danger', 'diff']">{{item.diff.segment_count_prc}}%</span>
          <span>({{item.diff.segment_count}})</span>
        </template>
        <template v-slot:item.query_count_main="{ item }">
          <span>{{item.main.query_count}}</span>
        </template>
        <template v-slot:item.query_count_ch="{ item }">
          <span>{{item.chstat.query_count}}</span>
        </template>
        <template v-slot:item.query_count_diff="{ item }">
          <span :class="[item.diff.query_count_prc !== 0 && 'danger', 'diff']">{{item.diff.query_count_prc}}%</span>
          <span>({{item.diff.query_count}})</span>
        </template>
        <template v-slot:item.url_count_main="{ item }">
          <span>{{item.main.url_count}}</span>
        </template>
        <template v-slot:item.url_count_ch="{ item }">
          <span>{{item.chstat.url_count}}</span>
        </template>
        <template v-slot:item.url_count_diff="{ item }">
          <span :class="[item.diff.url_count_prc !== 0 && 'danger', 'diff']">{{item.diff.url_count_prc}}%</span>
          <span>({{item.diff.url_count}})</span>
        </template>
      </v-data-table>
    </table-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TableContainer from '@/components/data/segments/tableContainer.vue'

export default {
	components: { TableContainer },
	data () {
		return {
			problematicSegmentsLoading: true,
			headers: [
				{
					text: 'Наименование проекта',
					value: 'project_name',
					sortable: false
				},
				{
					text: 'ID проекта',
					value: 'project_id',
					sortable: false
				},
				{
					text: 'Тип сегмента',
					value: 'segment_type',
					sortable: false
				},
				{
					text: 'Сегментов MAIN',
					value: 'segment_count_main',
					sortable: false
				},
				{
					text: 'Сегментов CH',
					value: 'segment_count_ch',
					sortable: false
				},
				{
					text: 'Сегментов Diff',
					value: 'segment_count_diff',
					sortable: false
				},
				{
					text: 'Запросов MAIN',
					value: 'query_count_main',
					sortable: false
				},
				{
					text: 'Запросов CH',
					value: 'query_count_ch',
					sortable: false
				},
				{
					text: 'Запросов Diff',
					value: 'query_count_diff',
					sortable: false
				},
				{
					text: 'Страниц MAIN',
					value: 'url_count_main',
					sortable: false
				},
				{
					text: 'Страниц CH',
					value: 'url_count_ch',
					sortable: false
				},
				{
					text: 'Страниц Diff',
					value: 'url_count_diff',
					sortable: false
				}
			],
			entity: {
				isReverse: false,
				type: 'default',
				segmentType: undefined
			}
		}
	},

	computed: {
		...mapState({
			projectTotal: state => state.options.projects.total
		}),
		...mapGetters({
			transformedProblematicSegments: 'segments/transformedProblematicSegments'
		}),
		segments () {
			return this.transformedProblematicSegments(this.entity)
		}
	},

	methods: {
		...mapActions({
			getProjectSegmentsCount: 'segments/getProjectSegmentsCount'
		}),

		async updTable (payload) {
			const query = {
				project_ids: payload.project
					? !Array.isArray(payload.project)
						? [payload.project]
						: payload.project
					: undefined,
				active: 1,
				only_diff: payload.isProblematic ? 1 : '0'
			}

			try {
				this.problematicSegmentsLoading = true
				await this.getProjectSegmentsCount({ ...query })
			} catch (error) {
				this.$notify({ type: 'error', title: error })
			} finally {
				this.problematicSegmentsLoading = false
			}
		},

		updSegmentType (payload) {
			if (payload.segmentType) {
				this.entity.segmentType = payload.segmentType
			} else {
				this.entity.segmentType = undefined
			}
		},
		generateLink (segmentType, projectId, isCH) {
			switch (segmentType) {
			case 'category':
				return `https://spa.seowork.ru/semantics/categories/${projectId}${isCH ? '?ch=1' : ''}`
			case 'query_group':
				return `https://spa.seowork.ru/semantics/query-groups/${projectId}${isCH ? '?ch=1' : ''}`
			case 'group':
				return `https://spa.seowork.ru/semantics/groups/${projectId}${isCH ? '?ch=1' : ''}`
			default:
				return '/'
			}
		},
		sortBy (type) {
			if (this.entity.type !== type) {
				this.entity.type = type
				this.entity.isReverse = false
			} else {
				this.entity.type = type
				this.entity.isReverse = !this.entity.isReverse
			}
		}
	}
}
</script>

<style lang="scss" scoped>
  .table-title {
    font-weight: 400;
    font-size: 28px;
  }
  .danger {
    color: red;
    font-weight: 500;
  }

  .header-btn {
    cursor: pointer;
  }

  ._pl-15 {
    padding-left: 15px;
  }

  .diff {
    margin-right: 5px;
  }

  .control-btn {
    margin-left: 5px;
  }

	.link-icon {
		display: none;
		margin-left: 5px;
	}

	.link-wrapper {
		&:hover {
			.link-icon {
				display: inline-block;
			}
		}
	}
</style>
